:root {
  --light: 300;
  --regular: 400;
  --medium: 500;
  --bold: 600;
  --extra-bold: 700;
  font-family: 'Roboto', serif;
}

body {
  line-height: 1.35 !important;
  font-family: 'Roboto', serif;
}

* {
  margin: 0;
  padding: 0;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

img {
  max-width: 100%;
  height: auto;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

h1 {
  font-size: 22px;
}

h2 {
  font-size: 20px;
}

h3 {
  font-size: 18px;
}

h4 {
  font-size: @font-size-lg;
}

h5 {
  font-size: @font-size-base;
}

h6 {
  font-size: @font-size-base;
}

.ant-w-100 {
  width: 100%;
}

.ant-d-flex {
  display: flex;
}

.ant-align {
  &-center {
    align-items: center;
  }

  &-start {
    align-items: flex-start;
  }

  &-end {
    align-items: flex-end;
  }
}

.ant-justify {
  &-center {
    justify-content: center;
  }

  &-start {
    justify-content: flex-start;
  }

  &-end {
    justify-content: flex-end;
  }
  &-between {
    justify-content: space-between;
  }
}

.ant-pagination-total-text {
  margin-right: auto;
}

.ant-pagination {
  margin-right: 16px!important;
  margin-left: 16px!important;
}

.ant-pagination-item-active {
  background-color: @primary-color;

  a {
    color: #fff;
  }

  &:hover {
    a {
      color: #fff;
    }
  }
}

.ant-form-vertical .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  position: absolute;
  right: -12px;
}

.ant-form-horizontal .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  position: absolute;
  right: 8px;
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
  margin-left: 10px;
}

.text-primary {
  color: @primary-color;
}
.font-bold {
  font-weight: bold;
}

.table-approve-history {
  td.ant-table-cell {
    vertical-align: top;
  }
}

.ant-upload-list-item-thumbnail {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ant-upload-list-picture .ant-upload-list-item, .ant-upload-list-picture-card .ant-upload-list-item {
  padding: 0;
}

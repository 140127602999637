@import '../../../shared/styles/variable.less';

.page-layout {
  max-width: 1440px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.auth-page-layout {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #012344;
    height: @header-height-sm;
    padding: 0 20px;
    .page-layout();
    &-action {
      display: flex;
      align-items: center;
      gap: 28px;
    }

    &-link {
      font-weight: @font-weight-medium;
      font-size: @font-size-base;
      color: @text-color-helps;
    }

    &-btn {
      background-color: @primary-color !important;
      color: @white-50 !important;
      padding: 8px 16px !important;

      min-width: 120px;
    }

    @media screen and (min-width: @screen-md) {
      padding: 0 100px;
      height: @header-height-lg;
      &-action {
        gap: 30px;
      }
      &-link {
        font-size: @font-size-lg;
      }
    }
  }
  &__body {
    .page-layout();
    @media screen and (min-width: @screen-md) {
      padding: 0 100px;
    }
    display: flex;
    justify-content: center;
  }
  &__footer {
    .page-layout();
    background: @white;
    padding: 4px 20px;
    display: flex;
    align-items: center;
    font-size: @font-size-base;
    color: @footer-text-color;
    line-height: 1.5;
    @media screen and (min-width: @screen-md) {
      font-size: @font-size-lg;
      padding: 8px 100px;
    }
  }
}
.auth-page-layout__header-container,
.auth-page-layout__footer-container {
  background: @white;
}
.auth-page-layout__header-container {
  padding: 0;
  height: fit-content;
  background: #012344;
}
.auth-page-layout__footer-container {
  padding: 0;
  display: flex;
  align-items: center;
}

@primary-color: #0176F2;
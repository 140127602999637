@import '../../../shared/styles/variable';

.question-btn-all {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  width: 100%;
  text-transform: capitalize;
}

.question-link {
  display: flex;
  align-items: center;
  font-size: @font-size-lg;
  border-radius: 0;
  margin-top: -10px;
  color: @text-color;

  &:hover,
  &:focus {
    color: @text-color;
    background-color: transparent;
  }

  @media screen and (min-width: @screen-md) {
    font-weight: @font-weight-medium;
    text-transform: uppercase;
    margin-top: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    color: @text-color-secondary;
    line-height: 1;

    &:hover,
    &:focus {
      color: @text-color;
    }
  }
}

.question-icon {
  position: relative;
  display: none;
  color: @white;

  @media screen and (min-width: @screen-md) {
    display: flex;
    align-items: center;
    color: @white;
  }
}

.question-text {
  font-size: @font-size-lg;
  font-weight: @font-weight-regular;

  @media screen and (min-width: @screen-md) {
    display: none;
  }
}

.question-list {
  padding-top: 0;
  padding-bottom: 0;
}

.question-scroll-submenu {
  height: 250px;

  @media screen and (min-width: @screen-xxl) {
    height: 380px;
  }
}

.question-header-message {
  width: 260px;
  padding: 0;

  @media screen and (min-width: @screen-sm) {
    width: 300px;
  }

  @media screen and (min-width: @screen-xxl) {
    width: 380px;
  }

  & .ant-dropdown-menu-item {
    padding: 0;
    white-space: normal;

    &:hover {
      background-color: transparent;
    }

    &.header {
      padding: 12px 20px;
      font-weight: @font-weight-medium;
      cursor: inherit;

      &:hover,
      &:focus {
        background-color: transparent;
      }
    }
  }
}

@primary-color: #0176F2;
@import '../../../../shared/styles/variable.less';

.page_breadcrumb-container.page_breadcrumb-padding {
  padding: 8px 12px;
  @media screen and (min-width: @screen-md) {
    padding: 16px 24px;
  }
}
.page_breadcrumb-container.page_breadcrumb-padding_only-title {
  padding: 14px 12px;
  @media screen and (min-width: @screen-md) {
    padding: 16px 24px;
  }
}
.page_breadcrumb-container {
  background-color: @white;

  .page_breadcrumb__page-row-title {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 4px;
    .page_breadcrumb__page-title_container {
      display: flex;
      align-items: center;
      gap: 8px;
      .page_breadcrumb__page-icon {
        font-size: 16px;
        display: flex;
        align-items: center;
        cursor: pointer;
      }
      .page_breadcrumb__page-title {
        font-size: @heading-3-size;
        line-height: 1.4;
        padding: 6px 0;
        margin: 0;
      }
    }
  }
  .page_breadcrumb-container {
    .page_breadcrumb__item {
      font-size: @font-size-sm;
      line-height: 1.57;
      cursor: default;
    }
  }
  @media screen and (min-width: @screen-md) {
    .page_breadcrumb__page-row-title {
      padding-bottom: 4px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;
      .page_breadcrumb__page-title_container {
        display: flex;
        align-items: center;
        gap: 16px;
        .page_breadcrumb__page-icon {
          font-size: 18px;
          display: flex;
          align-items: center;
          cursor: pointer;
        }
        .page_breadcrumb__page-title {
          font-size: @heading-2-size;
          font-weight: @font-weight-medium;
          line-height: 1.4;
          padding: 6px 0;
          margin: 0;
        }
      }
    }
    .page_breadcrumb-container {
      .page_breadcrumb__item {
        cursor: default;
        font-size: @font-size-base;
        line-height: 1.57;
      }
    }
  }
}

.page_breadcrumb-padding-tabs {
  padding-bottom: 0 !important;
}

.page_breadcrumb-container {
  .ant-tabs {
    .ant-tabs-nav {
      padding-top: 8px;
      margin-bottom: 0;
      .ant-tabs-tab {
        font-size: @font-size-lg !important;
        line-height: 1.5;
        color: #000000d9;
        padding: 8px 0;
      }
    }
  }

}

.page_breadcrumb-padding-fixed {
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  position: sticky;
  top: 48px;
}

@primary-color: #0176F2;
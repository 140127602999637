@import '../../../shared/styles/variable';

.main-content-view {
  padding: 24px 20px 44px 20px;
  //max-width: 1260px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  @media screen and (min-width: @screen-md) {
    padding: 24px 24px 66px 24px;
    //max-width: 1260px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }
}

.main-content-view-wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 100vh;
}

@primary-color: #0176F2;